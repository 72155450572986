<template>
  <div class="cursorBox bg000">
    <div v-if="apiShow">
      <PcHeader :parseVal="parseVal" :languageData="languageData" :isSelect="true" />
      <div class="main">
        <div v-if="loadingShow">
          <div class="banner-box">
            <swiper ref="mySwiper" :options="swiperOption">
              <swiper-slide class="swiper-slide">
                <img :src="require('@/assets/img/pc/home/banner/banner.png')">
                <img class="img1" :src="require('@/assets/img/pc/home/banner/roles.png')">
                <img class="img2" :src="require('@/assets/img/pc/home/banner/light1.png')">
                <img class="img3" :src="require('@/assets/img/pc/home/banner/light3.png')">
                <img class="img4" :src="require('@/assets/img/pc/home/banner/lightbg.png')">
                <img class="img5" :src="require('@/assets/img/pc/home/banner/light2.png')">
                <img class="img6" :src="require('@/assets/img/pc/home/banner/light4.png')">
              </swiper-slide>
              <swiper-slide v-for="item of swiperData" :key="item.id" class="swiper-slide">
                <img :src="'https://admin.gfg888.com/td/api/photo/' + item.computerSrc">
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          
          <div class="newGames" id="newGames">
            <div class="video-container" id="videoContainer">
              <div class="video-title bounceInBox">{{filterFn('filmText', 'title')}}</div>
              <div class="video-footer">
                <div v-if="!videoShow" class="video-bg" @click="videoBg"> 
                  <img :src="require('@/assets/img/pc/home/play.png')" class=""/>
                </div>
                <!--  -->
                <video id="bg_video" ref="videoPlayer" controls 
                  x5-video-player-fullscreen="true"
                  x5-playsinline
                  playsinline
                  webkit-playsinline
                  preload="auto" 
                  :poster="'https://admin.gfg888.com/td/api/photo/' + viedoFn('film', 'computerSrc')">
                  <!-- <source v-if="videoShow" src="@/assets/img/mobile/SLOTS_vidio.mp4" type="video/mp4" /> -->
                  <source v-if="videoShow" :src="'https://admin.gfg888.com/td/' + viedoFn('film', 'computerViedo')" type="video/mp4" />
                </video>
              </div>
            </div>
            <!-- <div class="newTit">{{ $t("newGames") }}</div> -->
            <div class="popular-box">
              <div class="popular-games-title bounceInBox">{{ filterFn('popularGameText', 'title') }}</div>
              <div class="popular-games-explain bounceInBox">{{ filterFn('popularGameText', 'text') }}</div>
              <div class="gameMain">
                <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star1"/>
                <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star2"/>
                <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star3"/>
                <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star5"/>
                <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star6"/>
                <img :src="require('@/assets/img/mobile/star_light.png')" class="breathe-div swiper-star4"/>
                
                <div class="gameBox" v-for="(item, index) in hotGameList" :key="index">
                  <pc-game-item :parseVal="parseVal" class="animate__animated gameFlipOutYBox" :game="item" ></pc-game-item>
                </div>
              </div>
            </div>
          </div>
          <div class="platForm">
            <div class="platForm-title bounceInBox">{{ filterFn('propagateText', 'title')}}</div>
            <div class="platForm-title2 bounceInBox" v-html="filterFn('propagateText', 'text')"></div>
            <div class="img-box"> 
              <img class="img" src="../../../src/assets/img/pc/home/yjzs.png" alt="" />
            
              <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate"/>
              <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate3"/>
              <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate4"/>
              <img :src="require('@/assets/img/mobile/b.png')" class="finish-animate6"/>

              <div class="seaShell">
                <img class="seaShell-img1" :src="require('@/assets/img/mobile/rainbow-new.png')" alt="" />
                <img class="seaShell-img2" :src="require('@/assets/img/mobile/star_light-new.png')" alt=""/>
              </div>
            </div>
            
          </div>
          <div class="games" id="games">
            <div class="tit bounceInBox">{{ filterFn('gamePresentationText', 'title')}}</div>
            <div class="des bounceInBox" v-html="filterFn('gamePresentationText', 'text')"></div>
            <!-- v-lazy-container="{ selector: 'img' }" -->
            <div class="scroll_arr">
              <vue-seamless-scroll :data="listData1" :class-option="classOption" touch="false" class="warp">
                <ul class="ul-item">
                  <li v-for="(item, index) in listData1" :key="index" class="li-item">
                    <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                  </li>
                </ul>
              </vue-seamless-scroll>
              <vue-seamless-scroll :data="listData2" :class-option="classOption2" class="warp">
                <ul class="ul-item">
                  <li v-for="(item, index) in listData2" :key="index" class="li-item">
                    <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                  </li>
                </ul>
              </vue-seamless-scroll>
              <vue-seamless-scroll :data="listData3" :class-option="classOption3" class="warp">
                <ul class="ul-item">
                  <li v-for="(item, index) in listData3" :key="index" class="li-item">
                    <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                  </li>
                </ul>
              </vue-seamless-scroll>
              <vue-seamless-scroll :data="listData4" :class-option="classOption4" class="warp">
                <ul class="ul-item">
                  <li v-for="(item, index) in listData4" :key="index" class="li-item">
                    <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                  </li>
                </ul>
              </vue-seamless-scroll>
              <vue-seamless-scroll :data="listData5" :class-option="classOption5" class="warp">
                <ul class="ul-item">
                  <li v-for="(item, index) in listData5" :key="index" class="li-item">
                    <img :src="imgFn(item)" @click="toGameDetails(item.gameId)">
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
            <!-- <a class="gamesMore" @click="goToGames">{{ $t("games_more") }}</a> -->
          </div>
          <div class="aboutUs" id="aboutUs" ref="aboutUs">
            <div class="aboutUsTit bounceInBox">{{ filterFn('aboutUsText', 'title') }}</div>
            <div class="aboutUsDes bounceInBox"  v-html="filterFn('aboutUsText', 'text')"></div>
            <div class="usMain">
              <div v-for="(item, index) in aboutData" :key="index" class="about-item animate__animated rubberBandBox">
                <number 
                  :ref="'aboutNum' + index"
                  :from="0" 
                  :to="item.num"
                  style="color: #ab72f8;"></number>
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
          <div class="qwrz" id="qw" style="position: relative;">
            <div class="night">
              <div class="shooting_star"></div>
              <div class="shooting_star"></div>
              <div class="shooting_star"></div>
              <div class="shooting_star"></div>
            </div>
            <div class="night2">
              <div class="shooting_star"></div>
              <div class="shooting_star"></div>
              <div class="shooting_star"></div>
              <div class="shooting_star"></div>
            </div>
            <p class="animate__animated bounceInBox" :class="{ animate__bounceIn: animate18 }">{{ filterFn('officialCertificationText', 'title') }}</p>
            <div class="qw_mid">
              <span class="animate__animated bounceInBox" :class="{ animate__bounceIn: animate19 }" v-html="filterFn('officialCertificationText', 'text')"></span>
            </div>
            <div class="q4box">
              <div style="width: 30%;">
                <div class="animateTxt animate__animated fadeInRight"><div class="q1">
                  <img :src="officialCertificationImg('officialCertificationImg', '框1')"/>
                </div>
                {{ filterCertificationFn('officialCertification', 'text', 'tableData1') }}
              </div>
                <div class="animateTxt animate__animated fadeInRight"><div class="q3"><img :src="officialCertificationImg('officialCertificationImg', '框3')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData3') }}</div>
              </div>
              <div style="width: 40%;text-align: center;">
                <img class="animate__animated2 animate__animated fadeInRight" :src="officialCertificationImg('officialCertificationImg', '主图')" />
              </div>
              <div style="width: 30%;">
                <div class="animateTxt animate__animated fadeInRight"><div class="q2"><img :src="officialCertificationImg('officialCertificationImg', '框2')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData2') }}</div>
                <div class="animateTxt animate__animated fadeInRight"><div class="q4"><img :src="officialCertificationImg('officialCertificationImg', '框4')"/></div>{{ filterCertificationFn('officialCertification', 'text', 'tableData4') }}</div>
              </div>
            </div>
          </div>
          <div class="about" id="about">
            <div class="tit bounceInBox">{{ filterFn('partnersText', 'title') }} </div>
            <div class="des bounceInBox" v-html="filterFn('partnersText', 'text')"></div>
            <div class="light animate__animated fadeInRight">
              <img :src="officialCertificationImg('partnersImg', '框1')" />
              <img :src="officialCertificationImg('partnersImg', '框2')" />
              <img :src="officialCertificationImg('partnersImg', '框3')" />
              <img :src="officialCertificationImg('partnersImg', '框4')" />
              <img :src="officialCertificationImg('partnersImg', '框5')" />
              <img :src="officialCertificationImg('partnersImg', '框6')" />
              <img :src="officialCertificationImg('partnersImg', '框7')" />
              <img :src="officialCertificationImg('partnersImg', '框8')" />
            </div>
          </div>
          <div class="vision" id="vision">
            <div class="visionBg">
              <div class="tit bounceInBox">{{ filterFn('contactUsText', 'title') }}</div>
              <div class="des bounceInBox" v-html="filterFn('contactUsText', 'text')"></div>
            </div>
            <div class="info-circle">
              <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Email' : 'Email', 'link')" target="_blank" class="item emailBg zoomInLeft animate__animated">
                <span>{{ contactUsFn('contactUs', includesFn ? 'gfg126-Email' : 'Email', 'text') }}</span>
              </a>
              <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Skype' : 'Skype', 'link')" target="_blank" class="item skypeBg zoomInDown animate__animated">
                <span>{{ contactUsFn('contactUs', includesFn ? 'gfg126-Skype' : 'Skype', 'text') }}</span>
              </a>
              <a :href="contactUsFn('contactUs', includesFn ? 'gfg126-Telegram' : 'Telegram', 'link')" target="_blank" class="item teleBg zoomInRight animate__animated">
                <span>{{ contactUsFn('contactUs', includesFn ? 'gfg126-Telegram' : 'Telegram', 'text') }}</span>
              </a>
            </div>
          </div>
          <Pcfooter :parseVal="parseVal" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PcHeader from "@/components/pc/PcHeader2.vue";
import Pcfooter from "@/components/pc/Pcfooter.vue";
import PcGameItem from "@/components/pc/PcGameItem.vue";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import { gameData, hotGameId } from '@/components/tool/GameConfig'
import { getList } from '@/api'
export default {
  name: '',
  props: { },
  components: {
    PcHeader,
    Pcfooter,
    Swiper,
    SwiperSlide,
    PcGameItem
  },
  data() {
    return {
      languageData: [],
      parseVal: [],
      swiperData: [],
      loadingShow: false,
      swiperOption: {
        pagination: {
          el: '.swiper-pagination', // 分页器容器的css选择器或HTML元素
          clickable: true // 分页器是否可点击
        }
      },
      listData1: [
        
      ],
      listData2: [
        
      ],
      listData3: [
        
      ],
      listData4: [
        
      ],
      listData5: [
        
      ],
      classOption: {
        step: 0.4,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption2: {
        step: 0.8,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption3: {
        step: 0.3,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption4: {
        step: 0.9,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      classOption5: {
        step: 0.5,
        limitMoveNum: 2,
        direction: 1,
        openTouch: false,
      },
      videoShow: false,
      lang: '',
      animate1: false,
      animate2: false,
      animate3: false,
      animate4: false,
      animate5: false,
      animate6: false,
      animate7: false,
      animate8: false,
      animate9: false,
      animate10: false,
      animate11: false,
      animate12: false,
      animate13: false,
      animate14: false,
      animate15: false,
      animate16: false,
      animate17: false,
      animate18: false,
      animate19: false,
      animate20: false,
      animate21: false,
      animate23: false,
      apiShow: false,
      // 热门游戏列表
      hotGameList: [],
      // 关于我们数据
      aboutData: [
        {
          number: 1,
          label: this.$i18n.t("mainData_p")
        },
        {
          number: 5,
          label: this.$i18n.t("mainCenter_p")
        },
        {
          number: 10,
          label: this.$i18n.t("mainLanguage_p")
        },
        {
          number: 24,
          label: this.$i18n.t("mainSafety_p")
        }
      ],
     }
  },
  methods: {
    loadApi() {
      this.apiShow = false
      getList({ 
        typeString: 'carousel,config,language,guideText,filmText,film,popularGameText,popularGame,propagateText,gamePresentationText,gamePresentation,aboutUsText,aboutUs,officialCertificationText,officialCertification,officialCertificationImg,partnersText,partnersImg,contactUsText,contactUs',
        pageIndex:1, pageSize: 999999, type: 'xxx'
      }).then(res => {
        if (res.data.code) {
          const val = res.data.data.items
          const parseVal = val.map(ele => {
            return { ...ele, ...JSON.parse(ele.jsonString)}
          })
          
          // 语言
          this.languageData = parseVal.filter(ele => ele.type === 'language')
          
          this.languageData.forEach((ele, index) => {
            if (!localStorage.getItem('LANG_NAME') && index === 0) {
              localStorage.setItem("LANG_NAME", ele.key1);
              this.lang = ele.key1
            }
          })

          if (!(this.languageData.map(ele => ele.key1).includes(localStorage.getItem('LANG_NAME')))) {
            localStorage.setItem("LANG_NAME", this.languageData[0].key1);
            this.lang = this.languageData[0].key1
          }

          this.parseVal = parseVal

          console.log(this.lang, 'ss8d7as8d7a9s7d9as7d9as7dasd')
          // 轮播图
          this.swiperData = parseVal.filter(ele => ele.type === 'carousel')
          // 热门游戏
          this.hotGameList = parseVal.filter(ele => ele.type === 'popularGame' && ele.isPc)
          console.log(this.hotGameList, 'this.hotGameList')
          // 游戏展示
          const gamePresentation = parseVal.filter(ele => ele.type === 'gamePresentation')
          const itemName = ['listData1', 'listData2', 'listData3', 'listData4', 'listData5']
          itemName.forEach((val, index) => {
            this[val] = gamePresentation.filter(ele => ele.key2 === (`tableData${index + 1}`)) || []
          })
          
          // 关于我们
          this.aboutData = []
          const tableDataVal = ['tableData1', 'tableData2', 'tableData3', 'tableData4']
          const aboutUs = parseVal.filter(ele => ele.type === 'aboutUs')
          aboutUs.forEach(ele => {
            if (tableDataVal.includes(ele.key2) && ele.name === this.lang) {
              this.aboutData[parseInt(ele.key2.match(/\d+/)[0] - 1)] = ele
            }
          })


        }
      }).finally(() => {
        this.apiShow = true
        this.loadingShow = true
        this.loadObserve()
      })
    },
    filterFn(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.key1 === this.lang)
      return item ? item[key] : ''
    },
    filterCertificationFn(val, key, tableName) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.filter(ele => ele.key1 === this.lang)
      const tableItem = item.find(ele => ele.key2 === tableName)
      return tableItem ? tableItem[key] : ''
    },
    officialCertificationImg(val, key) {
      const name = this.parseVal.filter(ele => ele.type === val)
      console.log(name, 'ss8ss8s8')
      const item = name.find(ele => ele.name === key)
      return item ? ('https://admin.gfg888.com/td/api/photo/' + item['computerSrc']) : ''
    },
    contactUsFn(val, key, contactName) {
      const name = this.parseVal.filter(ele => ele.type === val)
      const item = name.find(ele => ele.name === key)
      return item ? item[contactName] : ''
    },
    viedoFn(val, key) {
      const name = this.parseVal.find(ele => ele.type === val)
      return name ?  name[key] : ''
    },
    loadObserve() {
      this.$nextTick(() => {
    
        // 文字动画
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__bounceIn')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__bounceIn')
            }
          })
        })
        const cards = document.querySelectorAll('.bounceInBox')
        console.log('fffffffffffffffffff')
        cards.forEach((card, index) => {
          card.dataset.index = index
          observer.observe(card, index)
        })
        console.log(cards, 'cardscardscardscards')
        // 向右到左的动画
        const observer2 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__fadeInRight')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__fadeInRight')
            }
          })
        })
        const cards2 = document.querySelectorAll('.fadeInRight')
        cards2.forEach((card, index) => {
          card.dataset.index = index
          observer2.observe(card, index)
        })

        // 联系的动画
        const observer3 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.querySelector('.zoomInLeft').classList.add('animate__lightSpeedInLeft')
              entry.target.querySelector('.zoomInRight').classList.add('animate__lightSpeedInRight')
              entry.target.querySelector('.zoomInDown').classList.add('animate__zoomInDown')
            } else {
              // 当元素离开视窗时移除类名
              entry.target.querySelector('.zoomInLeft').classList.remove('animate__lightSpeedInLeft')
              entry.target.querySelector('.zoomInRight').classList.remove('animate__lightSpeedInRight')
              entry.target.querySelector('.zoomInDown').classList.remove('animate__zoomInDown')
            }
          })
        })
        const cards3 = document.querySelectorAll('.info-circle')
        cards3.forEach((card, index) => {
          card.dataset.index = index
          observer3.observe(card, index)
        })

        // 热门游戏动画
        const observer4 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              if (entry.target.dataset.index < 2) {
                entry.target.classList.add('animate__rotateInDownLeft')
              } else {
                entry.target.classList.add('animate__rotateInDownRight')
              }
            } else {
              // 当元素离开视窗时移除类名
              if (entry.target.dataset.index < 2) {
                entry.target.classList.remove('animate__rotateInDownLeft')
              } else {
                entry.target.classList.remove('animate__rotateInDownRight')
              } 
            }
          })
        })
        const cards4 = document.querySelectorAll('.gameFlipOutYBox')
        cards4.forEach((card, index) => {
          card.dataset.index = index
          observer4.observe(card, index)
        })

        // 动画
        const observer5 = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // 当元素进入视窗时添加类名
              entry.target.classList.add('animate__rubberBand')
              for (let i = 0; i < 4; i++) {
                const ele = this.$refs['aboutNum' + i ]
                ele && ele[0].restart()	
              }
            } else {
              // 当元素离开视窗时移除类名
              entry.target.classList.remove('animate__rubberBand')
            }
          })
        })
        const cards5 = document.querySelectorAll('.rubberBandBox')
        cards5.forEach((card, index) => {
          card.dataset.index = index
          observer5.observe(card, index)
        })
        


      })
    },
    // 获取热门游戏
    toGameDetails(id) {
      this.$router.push({
        path: '/pc/gameDisplayDetails',
        query: {
          id
        }
      })
    },
    imgFn(val) {
      console.log(val, 'valalsdlasldlasd')
      if (!val) return
      const item = val.gameItem.computerData.find(ele => ele.name2 === '封面')
      if (this.lang === '简体中文') {
          return 'https://admin.gfg888.com/td/api/photo/' + item.chinese
      } else {
          return 'https://admin.gfg888.com/td/api/photo/' + item.english
      }
    },
    // 获取热门游戏
    getHotGame() {
      this.hotGameList = []
      hotGameId.forEach(item => {
        gameData.forEach(game => {
          if (game.id === item) {
            if(this.hotGameList.length<4){
              this.hotGameList.push(game)
            }
          }
        })
      });
      console.log(this.hotGameList)
    },
    videoBg() {
      this.videoShow = true
      const video = this.$refs.videoPlayer;
      video.load();
      video.oncanplaythrough = () => {
        video.play();
      };
    },
    goToGames() {
      this.$router.push({ path: "/pc/gameDisplay" });
    },
  },
  created() {
    this.lang = localStorage.getItem("LANG_NAME")
    this.loadApi()
    // this.langChange(this.$i18n.locale)
    // this.getHotGame();
  },
  mounted() {
    // const _this = this
    // const time = setInterval(function () {
    //   // 判断文档和所有子资源(图片、音视频等)已完成加载
    //   console.log(document.readyState, 'document.readyStatedocument.readyState')
    //   if (document.readyState === 'complete') {
    //     _this.loadingShow = true
    //     _this.loadObserve()
    //     // 是否需要缓存
    //     // sessionStorage.setItem('loadingShow', '取消')
    //     clearInterval(time);
    //   }
    // }, 500)
  },
  watch: {

  },
  computed: {
    includesFn() {
      return location.host.includes('gfg126')
    },
    platFormTitle() {
      console.log(localStorage.getItem("LANG_NAME"))
      let html = ''
      if (localStorage.getItem("LANG_NAME") === 'zh') {
        html = `
        <div style="padding-right: 46px;font-weight: bold;">大运捕鱼</div>
        <div style="padding-left: 67px;padding-top: 9px;font-weight: bold;">爆金来袭</div>
        `
      } else if (localStorage.getItem("LANG_NAME") === 'tw'){
        html = `
        <div style="padding-right: 46px;font-weight: bold;">大運捕魚</div>
        <div style="padding-left: 67px;padding-top: 9px;font-weight: bold;">爆金來襲</div>
        `
      } else {
        html = this.$t('fishname')
      }
      return html
    }
  }
}
</script>

<style scoped lang="less">
@import './css/shootingStar.less';
@import './css/finish.less';
@import './css/seaShell.less';
@import './css/banner.less';
.main{
  background: #000;
  min-height: 100vh;
  overflow-x: hidden;
  // margin-top: 100 ./ @design-width * 95vw;
}
.color3{
  color: #333;
}
@design-width: 1920;
.px2vw(@name, @px) {
  @{name}: (@px / @design-width) * 100vw;
}
.newGames {
  width: 100%;
  // height: 100vh;
  background-color: #000;
  background-size: 100% 100%;
  background-image: url(~@/assets/img/pc/home/bg4.png);
  background-repeat: no-repeat;
  color: #fff;
  padding: 7.2vw 0;
  background-size: cover;
  .newTit {
    font-family: "fontTitle";
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0 auto 3.1vw;
    text-align: center;
    color: #fff;
  }
  .newDes {
    font-family: "fontText";
    padding: 0.5vw 3.1vw;
    line-height: 2vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
  }
  .newsH {
    width: 70vw;
    margin: 1vw auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9960ff;
    cursor:url('../../assets/img/pc/finger.png'),auto;
    .newsSpan {
      font-weight: 600;
    }
    img {
    .px2vw(width, 33);
    .px2vw(height, 28);
      margin: 0 0.5vw;
    }
  }

  .gameMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    position: relative;
    margin-top: 70 ./ @design-width * 100vw;
    .gameBox {
    .px2vw(width, 340);
    .px2vw(padding, 15);
    cursor:url('../../assets/img/pc/finger.png'),auto;
    }
  }
}
.platForm {
  position: relative;
  width: 100%;
  padding-top: 100 ./ @design-width * 100vw;
  background-size: 100% 100%;
  background-image: url(~@/assets/img/pc/home/bg3.png);
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  .img-box {
    position: relative;
  } 
  .img {
    padding: 0 200 ./ @design-width * 100vw;
    width: 100%;
  }
}
.games {
  width: 100%;
  // height: 100vh;
  background-image: url(~@/assets/img/pc/home/bg2.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  padding: 2.2vw 0 6.2vw;
  padding-top: 55 ./ @design-width * 100vw;
  .tit {
    font-family: 'fontText';
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0vw auto 20 ./ @design-width * 100vw;
    text-align: center;
  }
  .des {
    font-family: MicrosoftYaHei;
    padding: 70 ./ @design-width * 100vw 22.1vw;
    padding-top: 0;
    line-height: 30 ./ @design-width * 100vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
  }
  .newTit {
    font-family: "fontTitle";
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0 auto 3.1vw;
    text-align: center;
    color: #3b2121;
  }
  .newDes {
    font-family: "fontText";
    padding: 0.5vw 3.1vw;
    line-height: 30 ./ @design-width * 100vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
  }
  .newsH {
    width: 70vw;
    margin: 1vw auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9960ff;
    cursor:url('../../assets/img/pc/finger.png'),auto;
    .newsSpan {
      font-weight: 600;
    }
    img {
    .px2vw(width, 33);
    .px2vw(height, 28);
      margin: 0 0.5vw;
    }
  }
  .gamesBoxImg {
    // .px2vw(height, 2270);
    // width: 100%;
    // background: url(~@/assets/img/pc/gameDisplay.png) center no-repeat;
    // background-size: contain;
    // margin: 2.6vw auto;
    // &.zh {
    //   background: url(~@/assets/img/pc/gameDisplay_zh.png) center no-repeat;
    //   background-size: contain;
    // }
  }
  .gamesMore {
    .px2vw(height, 100);
    width: 70vw;
    margin: 0 auto 1vw;
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    color: #fff;
    background-image: linear-gradient(90deg, #9960ff 0%, #5f7eff 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100 ./ @design-width * 100vw;
    cursor:url('../../assets/img/pc/finger.png'),auto;
  }
  /* .scroll_arr {
    width: 90vw;
    height: 850 ./ @design-width * 100vw;
    margin-top: 100 ./ @design-width * 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
      .warp:nth-child(2),
      .warp:nth-child(4){
          height: 750 ./ @design-width * 100vw;
      }
    .warp {
      width: 100%;
      height: 850 ./ @design-width * 100vw;
      margin: 0 auto;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        &.ul-item {
          display: flex;
          flex-direction:column;
          height: 850 ./ @design-width * 100vw;
          .li-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 114 ./ @design-width * 100vw;
            height: 245 ./ @design-width * 100vw;
            margin: 5 ./ @design-width * 100vw;
          }
          
          .game {
            display: block;
            width: 114 ./ @design-width * 100vw;
            height: 245;
            // padding: 2 ./ @design-width * 100vw;
            border-radius: 36 ./ @design-width * 100vw;
            background: transparent;
            box-shadow: 2px 2px 15px 0 rgba(0,0,0,.18);
            img {
              width: 114 ./ @design-width * 100vw;
              height: 245 ./ @design-width * 100vw;
            }
          }
        }
      }
    }
  } */
}
.aboutUs {
  .px2vw(height, 944);
  width: 100%;
  background: url(~@/assets/img/pc/aboutUs_bg.jpg);
  background-size: cover;
  color: #000;
  padding: 4.7vw 0;
  .aboutUsTit {
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    letter-spacing: 0;
    margin: 0vw auto 20 ./ @design-width * 100vw;
    text-align: center;
    color: #fff;
    font-family: "fontText";
  }
  .aboutUsDes {
    padding: 0 22.2vw;
    padding-bottom: 160 ./ @design-width * 100vw;
    padding-top: 0;
    line-height: 30 ./ @design-width * 100vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
    color: #e7e7e7;
    font-family: MicrosoftYaHei;
  }
  .usMain {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
    > .about-item {
    .px2vw(width, 269);
    .px2vw(height, 278);
      margin: 1vw 0px;
      background: url(~@/assets/img/pc/aboutUs_frame.png) center no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 0.8vw 1.5vw;
      // > h2 {
      //   font-size: 80 ./ @design-width * 100vw;
      //   font-family: usFont;
      //   color: #ab72f8;
      // }
      p {
        width: 82%;
        font-size: 24 ./ @design-width * 100vw;
        color: #fff;
        text-align: center;
        line-height: 30 ./ @design-width * 100vw;
      }
      span {
        font-size: 66 ./ @design-width * 100vw;
        font-family: usFont;
        font-weight: bold;
        color: #9f9d9d;
      }
    }
  }
}
.about {
  font-family: 'fontTitle';
  width: 100%;
  // background: #fff;
  color: #fff;
  padding: 8.3vw 0;
  background-image: url(~@/assets/img/pc/home/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  .tit {
    font-size: 45 ./ @design-width * 100vw;
    font-family: 'fontText';
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 20 ./ @design-width * 100vw;
    text-align: center;
  }
  .des {
    padding: 0.5vw 22.2vw;
    line-height: 30 ./ @design-width * 100vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 3.1vw;
    color: #fff;
    font-family: MicrosoftYaHei;
  }
  .light {
    display: flex;
    width: 80%;
    height: auto;
    justify-content: center;
    flex-flow: wrap;
    margin: 0 auto;
    img {
    .px2vw(margin, 5);
    .px2vw(width, 340);
    .px2vw(height, 120);
    }
  }
}
.vision {
  .px2vw(height, 950);
  width: 100%;
  background: url(~@/assets/img/pc/contactUs/contactbg.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  // padding: 5vw 0 0;
  padding-top: 0;
  .visionBg{
    background: linear-gradient(90deg, #501B97, #7E429C);
    padding: 20 ./ @design-width * 100vw 0;
    margin-bottom: 200 ./ @design-width * 100vw;
  }
  .tit {
    font-family: 'fontText';
    font-size: 45 ./ @design-width * 100vw;
    font-weight: 700;
    letter-spacing: 0;
    margin-top: 30 ./ @design-width * 100vw;
    margin-bottom: 30 ./ @design-width * 100vw;
    text-align: center;
  }
  .des {
    padding: 0.5vw 22.2vw;
    line-height: 30 ./ @design-width * 100vw;
    font-size: 24 ./ @design-width * 100vw;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 40 ./ @design-width * 100vw;
    font-family: MicrosoftYaHei;
  }
  #line_video {
    width: 100%;
  }
}
.copyright{
  font-family: "fontText";
  padding: 1rem;
  width: 100%;
  background: #000;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14 ./ @design-width * 100vw;
  text-align: center;
}

.info-circle {
    .px2vw(padding, 15);
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  .item {
    .px2vw(width, 256);
    .px2vw(height, 255);
    position: relative;
    cursor:url('../../assets/img/pc/finger.png'),auto;
    span {
    .px2vw(top, 178);
      color: #fff;
      font-size: 14 ./ @design-width * 100vw;
      position: relative;
      display: block;
      height: auto;
      left: 0;
      width: 70%;
      line-height: 20 ./ @design-width * 100vw;
      word-wrap : break-word ;
      margin: 0 auto;
      text-align: center;
    }
  }
  .teleBg{
    background: url(~@/assets/img/pc/telegrambg.png) center no-repeat;
    background-size: contain;
  }
  .skypeBg{
    background: url(~@/assets/img/pc/skypebg.png) center no-repeat;
    background-size: contain;
  }
  .emailBg{
    background: url(~@/assets/img/pc/emailbg.png) center no-repeat;
    background-size: contain;
  }
}
.qwrz {
  position: relative;
  z-index:1;
  width: 100%;
  // height: 1600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background: #fafbfd;
  background: linear-gradient(45deg, #501B97, #7E429C);
  // background: url('~@/assets/img/mobile/qwrz.png');
  // background-repeat: no-repeat;
  // background-size: cover;
  // margin-top: -255px;
  > p {
    font-family: 'fontText' !important;
    font-size: 45 ./ @design-width * 100vw;
    color: #fff;
    letter-spacing: -1px;
    margin-bottom: 30 ./ @design-width * 100vw;
    font-weight: bold;
    padding-top: 60 ./ @design-width * 100vw;
  }
  .qw_mid {
    width: 60%;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 120 ./ @design-width * 100vw;
    text-align: center;
    span {
      // width: 60%;
      color: #fff;
      padding: 20px;
      line-height: 30 ./ @design-width * 100vw;
      font-size: 24 ./ @design-width * 100vw;
      font-family: MicrosoftYaHei;
      color: #fff;
    }
    // img {
    //   width: 50%;
    // }
  }
  .q4box {
    width: 80%;
    display: flex;
    // flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    z-index: 1; /* 将上方 div 置于上层 */
    // background: url('~@/assets/img/mobile/wizar2.gif)');
    .animate__animated2{
      width: 80%;
      margin: -50px auto 0;
    }
    .animateTxt {
      width: 70%;
      min-height: 282 ./ @design-width * 100vw;
      // font-size: 20px;
      padding: 90 ./ @design-width * 100vw 25 ./ @design-width * 100vw 41 ./ @design-width * 100vw 39 ./ @design-width * 100vw;
      margin: 0 auto 100px;
      line-height: 1.1;
      text-overflow: ellipsis;
      overflow: hidden;
      background: #2D114C;
      opacity: 0.8;
      border-radius: 40 ./ @design-width * 100vw;
      // margin-left: 24px;
      word-break: break-all;
      font-size: 20 ./ @design-width * 100vw;
      height: 423 ./ @design-width * 100vw;
      img {
        display: inline-block;
        width: 100%;
        height: 100%;
        // width: 199px;
        // height: 39px;
        
        // background: url('~@/assets/img/mobile/q1.png') no-repeat center;
        // background-size: contain;
      }
      .q1 {
        margin-bottom: 30 ./ @design-width * 100vw;
        height: 58 ./ @design-width * 100vw;
      }
      .q2 {
        margin-bottom: 30 ./ @design-width * 100vw;
        height: 44 ./ @design-width * 100vw;
      }
      .q3 {
        margin-bottom: 30 ./ @design-width * 100vw;
        margin-right: 40 ./ @design-width * 100vw;
        height: 56 ./ @design-width * 100vw;
      }
      .q4 {
        margin-bottom: 30 ./ @design-width * 100vw;
        height: 60 ./ @design-width * 100vw;
      }
    }
  }
}
.swiper-pagination-bullet {
  background: #fff;
}
.swiper-pagination-bullet-active {
  background: #fff;
}

.video-container {
    text-align: center;
    width: 1080 ./ @design-width * 100vw;
    margin: auto;
    .video-title {
      font-family: 'fontText';
      font-size: 45 ./ @design-width * 100vw;
      font-weight: 700;
      letter-spacing: 0;
      margin: 0 auto 3.1vw;
      text-align: center;
      color: #fff;
    }
    .video-bg {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      display: flex;
      justify-content: center;
      z-index: 3;
      background: lab(0 0 0 / 0.5);
      img {
        position: absolute;
        width: 244 ./ @design-width * 100vw;
        height: 244 ./ @design-width * 100vw;
        top: 50%;
        cursor:url('../../assets/img/pc/finger.png'),auto;
        margin-top: -122 ./ @design-width * 100vw;
      }
    }
    .video-footer {
      width: 1080 ./ @design-width * 100vw;
      // height: 750 ./ @design-width * 100vw;
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 auto;
      
      #bg_video {
        width: 1080 ./ @design-width * 100vw;
        height: 550 ./ @design-width * 100vw;
      }
      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20vw;
        height: 20vw;
        transform: translate(-50%, -50%);
        background-size: cover;
        border: none;
        border-radius: 50%;
        padding: 20px;
        cursor:url('../../assets/img/pc/finger.png'),auto;
      }
    }
  }
.scroll_arr {
    width: 100%;
    // height: 850px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20 ./ @design-width * 100vw 200 ./ @design-width * 100vw;
    padding-top: 39px;
    .warp {
      width: 100%;
      height: 900 ./ @design-width * 100vw;
      overflow: hidden;
      &:nth-child(2n) {
        height: 900 ./ @design-width * 100vw;
        margin-top: -110 ./ @design-width * 100vw;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        &.ul-item {
          display: flex;
          flex-direction: column;
          .li-item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 244 ./ @design-width * 100vw;
            height: 535 ./ @design-width * 100vw;
            margin: 20 ./ @design-width * 100vw;
            cursor:url('../../assets/img/pc/finger.png'),auto;
          }
          img {
            display: block;
            width: 244 ./ @design-width * 100vw;
            height: 535 ./ @design-width * 100vw;
            border-radius: 6 ./ @design-width * 100vw;
            background: transparent;
            box-shadow: 2px 2px 15 ./ @design-width * 100vw 0 rgba(0,0,0,.18);
          }
        }
      }
    }
  }
.platForm-title {
  width: 100%;
  position: absolute;
  top: 55 ./ @design-width * 100vw;
  text-align: center;
  font-size: 45 ./ @design-width * 100vw;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 44 ./ @design-width * 100vw;
  text-transform: uppercase;
  // margin: 0 auto;
  font-family: "fontText";
}
.platForm-title2 {
  width: 100%;
  position: absolute;
  top: 130 ./ @design-width * 100vw;
  text-align: center;
  font-size: 24 ./ @design-width * 100vw;
  color: #FFFFFF;
  line-height: 30 ./ @design-width * 100vw;
  font-family: MicrosoftYaHei;
}
.popular-box {
  position: relative;
}
.popular-games-title {
  width: 100%;
  text-align: center;
  font-size: 45 ./ @design-width * 100vw;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 44 ./ @design-width * 100vw;
  text-transform: uppercase;
  margin-top: 100 ./ @design-width * 100vw;
  font-family: "fontText";
  margin-bottom: 20 ./ @design-width * 100vw;
}
.popular-games-explain {
  width: 100%;
  padding: 0.5vw 22.1vw;
  padding-top: 0;
  text-align: center;
  font-size: 24 ./ @design-width * 100vw;
  color: #FFFFFF;
  line-height: 30 ./ @design-width * 100vw;
  font-family: MicrosoftYaHei;
}


.breathe-div {
    width: 100 ./ @design-width * 100vw;
    height: 100 ./ @design-width * 100vw;
    border-radius: 50%;
    text-align: center;
    cursor:url('../../assets/img/pc/finger.png'),auto;
    overflow: hidden;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-name: breathe;
    -webkit-animation-duration: 1500ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
}

.swiper-star1 {
  position: absolute;
  top: -100 ./ @design-width * 100vw;
  left: 190 ./ @design-width * 100vw;
  width: 150 ./ @design-width * 100vw;
  height: 150 ./ @design-width * 100vw;
  animation-delay: 0s;
  -webkit-animation-name: breathe3;
}
.swiper-star3 {
  position: absolute;
  top: 660 ./ @design-width * 100vw;
  left: 210 ./ @design-width * 100vw;
  width: 80 ./ @design-width * 100vw;
  height: 80 ./ @design-width * 100vw;
  animation-delay: 0s;
  -webkit-animation-name: breathe3;
}
.swiper-star2 {
  position: absolute;
  top: 75 ./ @design-width * 100vw;
  left: 120 ./ @design-width * 100vw;
  width: 150 ./ @design-width * 100vw;
  height: 150 ./ @design-width * 100vw;
  animation-delay: 1s;
  -webkit-animation-name: breathe3;
}
.swiper-star4 {
  position: absolute;
  width: 50 ./ @design-width * 100vw;
  height: 50 ./ @design-width * 100vw;
  top: -80 ./ @design-width * 100vw;
  right: 240 ./ @design-width * 100vw;
  animation-duration: 4s;
  animation-delay: 2s;
}
.swiper-star5 {
  position: absolute;
  top: -20 ./ @design-width * 100vw;
  right: 170 ./ @design-width * 100vw;
  width: 50 ./ @design-width * 100vw;
  height: 50 ./ @design-width * 100vw;
  animation-duration: 4s;
  animation-delay: 1s;
}
.swiper-star6 {
  position: absolute;
  top: 200 ./ @design-width * 100vw;
  right: 130 ./ @design-width * 100vw;
  animation-delay: 2s;
  -webkit-animation-name: breathe2;
}

@-webkit-keyframes breathe2 {
  0% {
    opacity: .2;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes breathe3 {
  0% {
    opacity: .2;
  }

  100% {
    opacity: 0.7;
  }
}

@-webkit-keyframes breathe {
  0% {
    opacity: .4;
  }

  100% {
    opacity: 1;
  }
}


/* 流星特效 */
.night {
  position: relative;
  width: 100%;
  height: 100% ./ @design-width * 100vw;
  transform: rotateZ(145deg);
  position: absolute;
  left: -400  ./ @design-width * 100vw;
  top: 700 ./ @design-width * 100vw;
}

.night2 {
  position: relative;
  width: 100%;
  height: 100% ./ @design-width * 100vw;
  transform: rotateZ(145deg);
  position: absolute;
  left: 440  ./ @design-width * 100vw;
  top: 400 ./ @design-width * 100vw;
}

.cursorBox {
  cursor:url('../../assets/img/pc/cursor.png'),auto;
}
.bg000 {
  background: #000;
  height: 100vh;
}
</style>